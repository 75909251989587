
import Vue from 'vue'
import { Component,Ref } from 'vue-property-decorator'
import validator from '@/utils/validator'
import Swal from 'sweetalert2'
import router from '@/router'
import api from '@/api'
import store from '@/store'

@Component
export default class Login extends Vue{
  @Ref() form!: HTMLFormElement
  showPassword = false
  validator = validator
  username = 'agus@gmail.com'
  password = '12345678'

  login() {
    if (this.form.validate()) {
      const credentials = {
        username: this.username,
        password: this.password,
      }
      api.auth.login(credentials).then(async(response) => {
        await store.dispatch('cekValidToken',response)
        this.showSuccessMessage('Login successful')
        this.redirectToDashboard()
      }).catch((error) => {
        this.showErrorMessage(error || 'An error occurred')
      })
    }
  }

  async showSuccessMessage(message) {
    await Swal.fire({
      title: 'Success',
      text: message,
      icon: 'success',
      timer: 2000,
      showConfirmButton: false,
    })
  }

  async showErrorMessage(message) {
    await Swal.fire({
      title: 'Oops',
      text: message,
      icon: 'error',
      showConfirmButton: true,
    })
  }

  redirectToDashboard() {
    router.push({ path: '/dashboard' }).catch(() => {})
  }

}
