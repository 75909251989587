import { ActionTree, Module, MutationTree, GetterTree } from 'vuex';
import { applicationModules, RootState } from '@/types';
import settingModel from '@/models/settingModel';
import requestPrintModel from '@/models/requestPrintModel'

const state: applicationModules = {
  loginFormState: false,
  settings: [],
  getSettingDataState: false,
  getLastPrintQueueLoket: []
};

export const getters: GetterTree<applicationModules, RootState> = {
  loginFormState: (data: applicationModules) => {
    return data.loginFormState
  },

  settingData: (data: applicationModules) => {
    return data.settings ?? []
  },

  getSettingDataState: (data: applicationModules) => {
    return data.getSettingDataState
  },
  getLastPrintQueueLoket: (data: applicationModules) => {
    return data.getLastPrintQueueLoket ?? []
  }
};

const mutations: MutationTree<applicationModules> = {
  setFormLoginState (state: applicationModules, data: boolean) {
    state.loginFormState = data
  },

  setSettingData (state: applicationModules, data: settingModel[]) {
    state.settings = data
  },

  setGetSettingDataState (state: applicationModules, data: boolean) {
    state.getSettingDataState = data
  },

  setGetLastPrintQueueLoket (state: applicationModules, data: requestPrintModel[]) {
    state.getLastPrintQueueLoket = data
  }
};

export const actions: ActionTree<applicationModules, RootState> = {
  changeLoginFormState ({ commit }, data: boolean) {
    commit('setFormLoginState', data);
  },

  updateSetting ({ commit }, data: settingModel[]) {
    commit('setSettingData', data)
  },

  updateGetSettingDataState ({ commit }, data: boolean) {
    commit('setGetSettingDataState', data)
  },
  upadateLastPrintQueue ({ commit }, data: requestPrintModel[]) {
    commit('setGetLastPrintQueueLoket', data)
  }
};

export const ApplicationModule: Module<applicationModules, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};