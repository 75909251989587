

import Vue from 'vue'
import {Component, Ref} from 'vue-property-decorator'
import api from '@/api'
import counterModel from '@/models/admin/counterModel'
import { popUp } from '@/utils'
import validator from '@/utils/validator'
import departmentModel from '@/models/admin/departmentModel'

@Component
export default class Counter extends Vue {
  @Ref() formInquery!: HTMLFormElement
  listCounter: counterModel[] = []
  listCounterSelected: counterModel = new counterModel()
  department: departmentModel[] = []
  search = ''
  loading = false
  dialog = false
  title = ''
  validator = validator
  flag = ''
  headers = [
    {
      text: 'KODE',
      align: 'start',
      value: 'id',
      cellClass: 'text-uppercase' 
    },
    { 
      text: 'LOKET', 
      value: 'name',
      cellClass: 'text-uppercase' 
    },
    { 
      text: 'RUANGAN', 
      value: 'department.name',
      cellClass: 'text-uppercase' 
    },
    { 
      text: 'KETERANGAN', 
      value: 'notes',
      cellClass: 'text-uppercase' 
    },
    { 
      text: 'ACTION', 
      value: 'action',
      cellClass: 'text-uppercase' 
    },
  ]

  loadData () {
    this.loading = true
    api.counter.getAll().then((response) => {
      this.listCounter = response.data.responseData
    }).catch((error) => {
      popUp.error(error)
    }).finally(() => {
      this.loading = false
    })
  }

  async departmentList () {
    try {
      const data = await api.department.getAll()
      this.department = data.data.responseData
    } catch (error) {
      popUp.error(error)
    }
  }

  show (item: counterModel) {
    this.title = 'Edit Counter: ' + item.name
    this.listCounterSelected = item
    this.flag ='Edit'
    this.dialog = true
  }

  tambahData () {
    this.title = 'Tambah Data'
    this.flag = 'Create'
    this.listCounterSelected = new counterModel()
    this.dialog = true
  }

  async simpan () {
    if (this.formInquery.validate()){
      if (this.flag === 'Edit') {
        await this.editCounter(this.listCounterSelected)
      } else if (this.flag === 'Create') {
        await this.postCounter(this.listCounterSelected)
      }
      this.loadData()
    }
  }

  hapus (item: counterModel) {
    popUp.confirm('Apakah yakin anda yakin akan menghapus data?').then((confirm) => {
      if (confirm) {
        this.deleteCounter(item.id)
      }
    })
  }

  async deleteCounter(id: number) {
    try {
      popUp.showLoading()
      const response = await api.counter.delete(id)
      this.dialog = false
      await popUp.successWithConfirmation(response.data.responseDesc)
      this.loadData()
    } catch (error) {
      popUp.error(error)
    } finally {
      popUp.dismisLoading()
    }
  }

  async postCounter (item: counterModel) {
    try {
      popUp.showLoading()
      const response = await api.counter.create(item)
      console.log(item)
      this.dialog = false
      await popUp.successWithConfirmation(response.data.responseDesc)
    } catch (error) {
      popUp.error(error)
    } finally {
      popUp.dismisLoading()
    }
  }

  async editCounter (item: counterModel) {
    try {
      popUp.showLoading()
      const response = await api.counter.update(item)
      this.dialog = false
      await popUp.successWithConfirmation(response.data.responseDesc)
    } catch (error) {
      popUp.error(error)
    } finally {
      popUp.dismisLoading()
    }
  }

  created () {
    this.loadData()
    this.departmentList()
  }
}
