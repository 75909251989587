import apiService from '@/api/apiService'
import { AxiosPromise, AxiosRequestConfig } from 'axios'
import callsDataModel from '@/models/calls/callsDataModel'

const axiosOption = apiService

export default {
  postCalls (item: callsDataModel) {
    const apiService: AxiosRequestConfig = {
      method: 'post',
      url: '/calls',
      data:item
    }
    return axiosOption(apiService)
  },

  lastCalls (date: string, department: number, length: number) {
    const apiService: AxiosRequestConfig = {
      method: 'get',
      url: '/calls/last',
      params: {
        date: date,
        departmentId: department,
        length: length
      }
    }
    return axiosOption(apiService)
  },
  lastCallsWithCounter (date: string, department: number, counterId: number, length: number) {
    const apiService: AxiosRequestConfig = {
      method: 'get',
      url: '/calls/last',
      params: {
        date: date,
        departmentId: department,
        counterId: counterId,
        length: length
      }
    }
    return axiosOption(apiService)
  },
  scanInOut (noPendaftaran: string, queueType: string) {
    const apiService: AxiosRequestConfig = {
      method: 'post',
      url: '/calls/scanInOut',
      data: {
        noPendaftaran: noPendaftaran,
        queueType: queueType
      }
    }
    return axiosOption(apiService)   
  }
}