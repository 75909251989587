

import Vue from 'vue'
import { Component, Ref } from 'vue-property-decorator'
import validator from '@/utils/validator'
import api from '@/api'
import { popUp } from '@/utils'

@Component
export default class ScanPoli extends Vue{
  @Ref() formText!: HTMLFormElement
  validator = validator
  registrationId = ''
  loading = false
  errorText = ''

  created () {
    document.addEventListener('keydown', (event) => {
      if (Number(event.key) >= 0 && Number(event.key) <= 9) {
        this.hitButton(event.key)
      } else if (event.key === 'Backspace') {
        this.backspaceAction()
      } else if (event.key === 'Delete') {
        this.backspaceAction()
      } else if (event.key === 'Enter') {
        this.nextStep()
      }
    })
  }

  hitButton (number) {
    this.registrationId += number
    this.errorText = ''
  }

  backspaceAction () {
    if (this.registrationId) {
      const str = this.registrationId.slice(0, -1)
      this.registrationId = str
      this.errorText = ''
    }
  }

  async nextStep () {
    if (this.formText) {
      if (this.formText.validate() && this.loading === false) {
        try {
          this.errorText = ''
          this.loading = true
          popUp.showLoading()
          const response = await this.scanInOut(this.registrationId)
          const message = response
          popUp.success(message)
        } catch (error) {
          if (typeof error === "string") {
            this.errorText = error
            popUp.dismisLoading()
          } else {
            popUp.error(error)
          }
        } finally {
          this.reset()
        }
      }
    }
  }

  reset () {
    this.formText.reset()
    this.loading = false
    this.registrationId = ''
  }

  scanInOut (noPendaftaran: string) {
    return new Promise((resolve, reject) => {
      const type = 'ruangan'
      api.calls.scanInOut(noPendaftaran, type).then((result) => {
        resolve(result.data.responseDesc || 'Scan berhasil.')
      }).catch((error) =>{
        reject(error.toString())
      })
    })
  }
}
