import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

import Dashboard from '@/views/Dashboard.vue'
import DisplayView1 from '@/views/DisplayView1.vue'
import MainLayout from '@/components/layouts/MainLayout.vue'
import DisplayLayout from '@/components/layouts/DisplayLayout.vue'
import Kiosk from '@/views/Kiosk.vue'
import NotFound from '@/components/layouts/NotFound.vue'
import AntrianLoket from '@/views/AntrianLoket.vue'
import Portal from '@/views/Portal.vue'
import ScanPoli from '@/views/ScanPoli.vue'
import Login from '@/views/auth/Login.vue'

import userModule from '@/store/modules/userModule'
import AdminPage from '@/views/admin/AdminPage.vue'

Vue.use(Router)

class RouteMeta {
  title: string
  requiresAuth: boolean
  constructor({ title, requiresAuth }: { title: string; requiresAuth: boolean }) {
    this.title = title
    this.requiresAuth = requiresAuth
  }
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'portal',
      component: Portal,
      meta: new RouteMeta({ title: 'Simantri - Portal', requiresAuth: false }),
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: new RouteMeta({ title: 'Simantri - Login', requiresAuth: false }),
    },
    {
      path: '/dashboard',
      component: MainLayout,
      children: [
        {
          path: '/',
          name: 'dashboard',
          component: Dashboard,
          meta: new RouteMeta({ title: 'Dashboard', requiresAuth: true })
        }
      ]
    },
    {
      path: '/antrian',
      component: MainLayout,
      children: [
        {
          path: '/antrian/loket',
          name: 'antrianLoket',
          component: AntrianLoket,
          meta: new RouteMeta({ title: 'ANTRIAN LOKET PENDAFTARAN', requiresAuth: true })
        },
        {
          path: '/antrian/kiosk/loket',
          name: 'kiosk',
          component: Kiosk,
          meta: new RouteMeta({ title: 'CETAK ANTRIAN LOKET', requiresAuth: false })
        },
        {
          path: '/antrian/poliklinik/scan',
          name: 'scanPoli',
          component: ScanPoli,
          meta: new RouteMeta({ title: 'SCAN ANTRIAN POLIKLINIK', requiresAuth: false })
        }
      ]
    },
    {
      path: '/queueDisplay',
      component: DisplayLayout,
      children: [
        {
          path: '/queueDisplay/pendaftaran',
          name: 'displayPendaftaran',
          component: DisplayView1,
          meta: new RouteMeta({ title: 'Display Antrian Pendaftaran', requiresAuth: false })
        }
      ]
    },
    { path: "*", component: NotFound },
    {
      path: '/admin',
      component: MainLayout,
      children: [
        {
          path: '/admin/page',
          name: 'admin',
          component: AdminPage,
          meta: new RouteMeta({ title: 'Administrator', requiresAuth: true })
        },
      ]
    },
  ]
})

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = localStorage.token
  const routeMeta = to.meta as RouteMeta
  const newTitle = `SIMANTRI-APP | ${routeMeta.title}`
  store.dispatch('topToolbar/changeTitle', newTitle)

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      return next({ name: 'portal' })
    }
    const userValid = await userModule.state.user
    if (!userValid) {
      await store.dispatch('cekValidToken', isAuthenticated)
    }
  } else if (isAuthenticated) {
    const userValid = await userModule.state.user
    if (!userValid) {
      await store.dispatch('cekValidToken', isAuthenticated)
    }
  }
  next()
})


export default router
