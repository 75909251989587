import registrationModel from '@/models/registrationModel'
import counterModel from "@/models/admin/counterModel"
export default class listQueueLoketModel {
  id!: number
  date!: string
  number!: string
  letter!: string
  called!: number
  departmentId!: number
  counterId!: number
  queueType!: string
  registrationInfoId!: string
  parentQueueId!: string
  lastCall!: string
  ended!: string
  createdAt!: string
  updatedAt!: string
  counter: counterModel = new counterModel()
  registrationData!: registrationModel
} 