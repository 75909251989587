import apiService from '@/api/apiService'
import UserModel from '@/models/auth/userModel'
import { AxiosPromise, AxiosRequestConfig } from 'axios'

const axiosOption = apiService

export default {
  getAll (): AxiosPromise{
    const apiService: AxiosRequestConfig = {
      method: 'get',
      url: '/users',
    }
    return axiosOption(apiService)
  },
  create (item: UserModel): AxiosPromise{
    const apiService: AxiosRequestConfig = {
      method: 'post',
      url: '/user',
      data: item
    }
    return axiosOption(apiService)
  },
  update (item: UserModel): AxiosPromise{
    const apiService: AxiosRequestConfig = {
      method: 'put',
      url: '/user/'+item.id,
      data: item
    }
    return axiosOption(apiService)
  },
  delete (item: number): AxiosPromise{
    const apiService: AxiosRequestConfig = {
      method: 'delete',
      url: '/user/'+item,
    }
    return axiosOption(apiService)
  },
}