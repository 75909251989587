

import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import CallMessageWsModel from '@/models/CallMessageWsModel'
import { Getter } from 'vuex-class'
import store from '@/store'
import api from '@/api'

import polyclinicModel from '@/models/schedules/polyclinicSchedulesModel'
import doctorModel from '@/models/schedules/doctorSchedulesModel'
import createQueueRequestModel from '@/models/queues/createQueueRequestModel'
import createQueueResponseModel from '@/models/queues/createQueueResponseModel'
import requestPrintModel from '@/models/requestPrintModel'
import { popUp } from '@/utils'

@Component({
  components: {
  }
})

export default class Kiosk extends Vue {
  @Getter('isConnected', { namespace: 'WsMessageHandler' }) wsConnectionState!: boolean;
  @Getter('message', { namespace: 'WsMessageHandler' }) wsMessage!: string;
  @Getter('getLastPrintQueueLoket', { namespace: 'ApplicationModule' }) lastPrintQueueData!: requestPrintModel
  dialog = false

  chanel = 'newQueue'
  listPolyclinic: polyclinicModel[] = []
  scheduleDoctor: Array<doctorModel> = []

  async getSchedules () {
    popUp.showLoading()
    api.schedules.poli().then((response) => {
      this.listPolyclinic = response.data.responseData
      popUp.dismisLoading()
    }).catch((error) => {
      popUp.error('Gagal mengambil data poliklinik.')
    })
  }

  created() {
    this.getSchedules()
  }

  sendCall (data: CallMessageWsModel) {
    store.dispatch('WsMessageHandler/sendCallMessage', JSON.stringify(data))
  }


  async showDetail(data: polyclinicModel) {
    try {
      popUp.showLoading()
      const response = await api.schedules.getDokter(data.KdRuangan)
      this.scheduleDoctor = response.data.responseData
      this.dialog = true
      popUp.dismisLoading()
    } catch (error) {
      popUp.error(error || 'Gagal saat mengambil jadwal dokter.')
    }
  }

  async selected (value: doctorModel) {
    try {
      popUp.showLoading()
      const formData = new createQueueRequestModel()
      formData.date = this.toDay().toString()
      formData.departmentId = '20'
      formData.queueType = 'pendaftaran'
      formData.kdRuangan = value.kdRuangan
      formData.namaRuangan = value.NamaRuangan
      formData.idDokter = value.idDokter
      formData.namaDokter = value.NamaLengkap
      //postQueque
      const newQueue = await this.postNewQueue(formData)
      //sendWsMessage
      const callData: CallMessageWsModel = new CallMessageWsModel()
        callData.channel = this.chanel
        callData.data.call = ''
      
      const requestPrint = new requestPrintModel()
        requestPrint.dokterPemeriksa = formData.namaDokter
        requestPrint.noAntrian = newQueue.letter
        requestPrint.ruanganPerawatan = formData.namaRuangan
        requestPrint.printerName = 'Receipt'
      await store.dispatch('ApplicationModule/upadateLastPrintQueue', requestPrint)
      
      await this.sendCall(callData)
      await this.printQueue(requestPrint)
      this.dialog = false
      popUp.successWithConfirmation('nomor antrian anda: '+newQueue.letter+' ,silahkan ambil tiket antrian anda.')
    } catch (error) {
      this.dialog = false
      popUp.error(error)
    }
  }

  async retryPrint (item: requestPrintModel) {
    try {
      popUp.showLoading()
      await this.printQueue(item)
    } catch (error) {
      popUp.error(error)
    } finally {
      popUp.dismisLoading()
    }
  }

  printQueue (formData: requestPrintModel) {
    return new Promise<createQueueResponseModel>((resolve, reject) => {
      api.printServer.sendPrintQueue(formData).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  postNewQueue (item: createQueueRequestModel) {
    return new Promise<createQueueResponseModel>((resolve, reject) => {
      api.queue.createQueue(item).then(({ data }) => {
        resolve(data.responseData)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  toDay () {
    let result = ''
    const today = new Date(new Date().toLocaleString('en-US'))

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0') // Month is 0-based, so add 1 and pad with '0' if needed.
    const day = String(today.getDate()).padStart(2, '0')

    const formattedDate = `${year}-${month}-${day}`
    result = formattedDate
    return result
  }

}

