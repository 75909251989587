import counterModel from "../admin/counterModel"
export default class UserModel {
  id!: number
  name!: string
  email!: string
  createdAt!: string
  password!: string
  updatedAt!: string
  counter!: counterModel
  counterId!: number
}