import apiService from '@/api/apiService'
import { AxiosPromise, AxiosRequestConfig } from 'axios'

const axiosOption = apiService

export default {
  poli (): AxiosPromise{
    const apiService: AxiosRequestConfig = {
      method: 'get',
      url: '/poli',
    }
    return axiosOption(apiService)
  },
  getDokter (kdRuangan: string): AxiosPromise{
    const apiService: AxiosRequestConfig = {
      method: 'get',
      url: '/dokter',
      params: {
        kodeRuangan: kdRuangan
      }
    }
    return axiosOption(apiService)
  },
}