
import Vue from 'vue'
import { Component, Watch, Ref } from 'vue-property-decorator'
import { general, popUp } from '@/utils'
import { Getter } from 'vuex-class'
import CallMessageWsModel from '@/models/CallMessageWsModel'
import QueueHelper from '@/utils/queueHelper'
import api from '@/api'
import lastCallModel from '@/models/queues/listQueueLoketModel'

@Component
export default class DisplayView1 extends Vue {
  @Getter('isConnected', { namespace: 'WsMessageHandler' }) connectionState!: boolean;
  @Getter('message', { namespace: 'WsMessageHandler' }) wsMessage!: string;
  @Ref() videoElement!: HTMLFormElement
  loketItems: lastCallModel[] = []

  videos: string[] = []
  liveTime = ''
  dayName = ''
  longDate = ''
  currentCallData: CallMessageWsModel = new CallMessageWsModel()
  subscribedChannel = ['newcall','recall']
  queue: QueueHelper = new QueueHelper()
  isplaying = false
  myVideo = document.getElementById("myVideo");
  departmentId = ''
  runningTextInfo = ''
  currentVideoPlaying = 0

  toDay () {
    let result = ''
    const today = new Date(new Date().toLocaleString('en-US'))

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0') // Month is 0-based, so add 1 and pad with '0' if needed.
    const day = String(today.getDate()).padStart(2, '0')

    const formattedDate = `${year}-${month}-${day}`
    result = formattedDate
    return result
  }

  lastQueue () {
    api.calls.lastCalls(this.toDay().toString(), Number(this.departmentId), 4).then((result) => {
      const lastQueueList = result.data.responseData
      lastQueueList.forEach((data: lastCallModel, index: number) => {
        this.loketItems[index] = Object.assign({}, data)
      })
    }).catch((err) => {
      popUp.error(err)
    });
  }

  @Watch('wsMessage', { deep: true })
  onChangeMessage (newVal: string) {
    const jsonData = JSON.parse(newVal)
    const newChannel = jsonData.channel || ''
    if (newChannel && this.subscribedChannel.find(el => el === newChannel)) {
      this.queue.enqueue(jsonData)
    }
  }

  @Watch('queue.length')
  async onChangeLengtQueue () {
    if (this.isplaying === false && this.queue.length > 0) {
      this.videoElement.volume = 0.0
      this.lastQueue()
      await this.playAudioCalling(this.queue.peek())
      this.videoElement.volume = 0.3
      this.queue.dequeue()
    }
  }

  async created () {
    try {
      this.createLiveTime()
      await this.departmentPendaftaranIsSet()
      await this.createInitialLoket()
      this.playvideo()
      this.lastQueue()
    } catch (error) {
      popUp.error(error, 'Mengerti')
    }  
  }

  departmentPendaftaranIsSet () {
    return new Promise((resolve, reject) => {
      this.departmentId = general.getSettingValueByName('DEPARTMENT-PENDAFTARAN')
      if (!this.departmentId) {
        reject('Mapping department pendaftaran belum ditentukan, setup melalui menu admin.')
      } else {
        resolve(true)
      }
    })
  }

  createInitialLoket () {
    return new Promise((resolve) => {
      const loketCount = 4
      for (let i = 0; i < loketCount; i++) {
        const dummyData = new lastCallModel()
        this.loketItems.push(dummyData)
      }
      this.runningTextInfo = general.getSettingValueByName('DISPLAY-PENDAFTARAN-INFO') || ''
      const strVideos = general.getSettingValueByName('DISPLAY-PENDAFTARAN-VIDEOS') || null
      this.videos = strVideos ? strVideos.split(',') : []
      resolve(true)
    })
  }

  mounted() {
    this.videoElement.autoplay = true; // Set autoplay to true
    this.videoElement.volume = 0.3; 
  }

  async playAudioCalling (callData: CallMessageWsModel) {
    return new Promise(async (resolve) => {
      const callNumber = callData.data.call
      const newCallNumber = callNumber
      const loketNumber = callData.loket.notes || 1
      const part1 = ['bell.mp3','nomor.mp3']
      const part2 = await general.cacahStringToMp3(newCallNumber)
      const part3 = ['silakan.mp3', loketNumber + '.mp3']
      const audioFile = part1.concat(part2, part3)
      this.isplaying = true
      this.currentCallData = callData
      for (let i of audioFile) {
        const result = await this.playAudio(i)
        if (!result) console.log('Error playing sound ' + i)
      }
      this.isplaying = false
      resolve(true)
    })
  }

  playAudio (fileName: string) {
    return new Promise((resolve) => {
      try {
        const audio = new Audio('../audios/' + fileName)
        audio.play().then(() => {
          audio.onended = () => { resolve(true) }
        }).catch((error) => {
          console.log(error)
          resolve(false)
        })
      } catch {
        resolve(false)
      }
    })
  }

  createLiveTime () {
    setTimeout(() => {
      const res = general.liveTime()
      this.liveTime = res.time
      this.dayName = res.dayName
      this.longDate = res.longDate
      this.createLiveTime()
    }, 1000)
  }

  async playvideo () {
    if (this.currentVideoPlaying < this.videos.length) {
      await this.playNextVideo(this.videos[this.currentVideoPlaying])
      this.currentVideoPlaying++
      this.playvideo()
    } else {
      this.currentVideoPlaying = 0
      this.playvideo()
    }
  }

  playNextVideo (url: string) {
    return new Promise((resolve) => {
      try {
        this.videoElement.src = url
        this.videoElement.onended = () => { resolve(true) }
      } catch {
        resolve(true)
      }
    })
  }
}
