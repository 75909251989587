import apiSimrs from '@/api/apiSimrs'
import { AxiosRequestConfig } from 'axios'

const axiosOption = apiSimrs

export default {
  inqueryPendaftaran(noCM: string, visitDate: string) {
    const apiService: AxiosRequestConfig = {
      method: 'get',
      url: '/getpasien/registration/search/detail',
      params: {
        noCm: noCM,
        visitDate: visitDate
      }
    }
    return axiosOption(apiService)
  }
}