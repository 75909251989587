
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ListDepartment from '@/components/admin/Department.vue'
import ListCounter from '@/components/admin/Counter.vue'
import ListUsers from '@/components/admin/Users.vue'

@Component({
  components: {
    ListDepartment,
    ListCounter,
    ListUsers
  }
})
export default class AdminPage extends Vue {
  tab = 0
}
