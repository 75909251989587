import printServer from "@/api/printServer"
import auth from "@/api/auth/authService"
import department from "@/api/admin/departmentService"
import application from "@/api/admin/applicationService"
import schedules from "@/api/scheduleService"
import queue from '@/api/queueService'
import calls from '@/api/callsService'
import simrs from "@/api/simrsService"
import counter from '@/api/admin/counterService'
import user from "@/api/admin/userService"

export default {
  printServer,
  schedules,
  auth,
  department,
  queue,
  calls,
  simrs,
  application,
  counter,
  user
}