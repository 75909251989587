import apiService from '@/api/apiService'
import { AxiosPromise, AxiosRequestConfig } from 'axios'
import counterModel from '@/models/admin/counterModel'

const axiosOption = apiService

export default {
  getAll (): AxiosPromise{
    const apiService: AxiosRequestConfig = {
      method: 'get',
      url: '/counters',
    }
    return axiosOption(apiService)
  },

  create (item: counterModel): AxiosPromise{
    const apiService: AxiosRequestConfig = {
      method: 'post',
      url: '/counter',
      data: item
    }
    return axiosOption(apiService)
  },
  update (item: counterModel): AxiosPromise{
    const apiService: AxiosRequestConfig = {
      method: 'put',
      url: '/counter/'+item.id,
      data: item
    }
    return axiosOption(apiService)
  },
  delete (item: number): AxiosPromise{
    const apiService: AxiosRequestConfig = {
      method: 'delete',
      url: '/counter/'+item,
    }
    return axiosOption(apiService)
  },
  
}