
import { Component, Vue, Watch } from 'vue-property-decorator'
import store from './store'
import { Getter } from 'vuex-class'
import LoginForm from '@/components/LoginForm.vue'
import { popUp } from './utils'
import api from './api'

@Component({
  components: {
    LoginForm
  }
})

export default class extends Vue {
  @Getter('loginFormState', { namespace: 'ApplicationModule' }) showLoginDialog!: boolean

  snackbar = false
  closeSeconds = 5000
  toastText = 'Connect Websocket timeout, retrying ...'
  @Getter('isConnected', { namespace: 'WsMessageHandler' }) connectionState!: boolean;

  @Watch('connectionState')
  onChangeConnection () {
    if (this.connectionState === false) {
      this.snackbar = true
      this.toastText = 'Connect Websocket timeout, retrying ...'
      setTimeout(() => {
        this.onChangeConnection()
        if (this.connectionState === false) {
          this.connectWebsocket()
        }
      }, 5000)
    } else {
      this.toastText = 'Websocket Connected.'
      this.snackbar = true
    }
  }

  async created () {
    await this.getSettingData()
    const connected = await store.dispatch('WsMessageHandler/connect')
    if (connected === false) {
      this.onChangeConnection()
    } else {
      this.toastText = 'Websocket Connected.'
      this.snackbar = true
    }
  }

  connectWebsocket () {
    store.dispatch('WsMessageHandler/connect')
  }

  async getSettingData () {
    try {
      const response = await api.application.getSetting()
      store.dispatch('ApplicationModule/updateSetting', response.data.responseData)
      store.dispatch('ApplicationModule/updateGetSettingDataState', true)
    } catch (error) {
      popUp.error('Gagal inisiasi data.')
    }
  }
  
}
