export default class createQueuePolyRequest {
  date!: string
  noPendaftaran!: string
  noIdentitas!: string
  namaPasien!: string
  jenisKelamin!: string
  umur!: string
  tglMasuk!: string
  kecamatan!: string
  alamat!: string
  kodeKelas!: string
  kodeJenisTarif!: string
  kdRuangan!: string
  namaRuangan!: string
  idDokter!: string
  namaDokter!: string
  kelurahan!: string
  noAntrian!: string
  kdInstalasi!: string
  kdKelompokPasien!: string
  idPenjamin!: string
  noRm!: string
  noCm!: string
  tglLahir!: string
  parentQueueId!: string
}