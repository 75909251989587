import apiService from '@/api/apiService'
import { AxiosPromise, AxiosRequestConfig } from 'axios'
import createQueueRequestModel from '@/models/queues/createQueueRequestModel'
import inqueryResponseModel from '@/models/inqueryResponseModel'
import createQueuePolyRequest from '@/models/queues/createQueuePolyRequest'

const axiosOption = apiService

export default {
  createQueue (item: createQueueRequestModel): AxiosPromise{
    const apiService: AxiosRequestConfig = {
      method: 'post',
      url: '/queue/createQueue',
      data: item
    }
    return axiosOption(apiService)
  },
  getDokter (kdRuangan: string): AxiosPromise{
    const apiService: AxiosRequestConfig = {
      method: 'get',
      url: '/dokter',
      params: {
        kodeRuangan: kdRuangan
      }
    }
    return axiosOption(apiService)
  },

  getListQueue (department: number, date: string, queueType: string) {
    const apiService: AxiosRequestConfig = {
      method: 'get',
      url: '/queues/department/'+department,
      params: {
        date: date,
        queueType: queueType
      }
    }
    return axiosOption(apiService)
  },

  createPolyclinicQueue (item: createQueuePolyRequest) {
    const apiService: AxiosRequestConfig = {
      method: 'post',
      url: '/queue/create/registration',
      data: item
    }
    return axiosOption(apiService)
  }
}