export default class requestPrintModel {
  dokterPemeriksa!: string
  noAntrian!: string
  ruanganPerawatan!: string
  printerName!: string
  noPendaftaran!: string
  namaPasien!: string
  tglLahir!: string
  jenisKelamin!: string
  ruang!: string
  dokter!: string
  alamat!: string
  tipe!: string
}
