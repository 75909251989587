

import Vue from 'vue'
import {Component, Ref} from 'vue-property-decorator'
import api from '@/api'
import departmentModel from '@/models/admin/departmentModel'
import { popUp } from '@/utils'
import validator from '@/utils/validator'

@Component
export default class Department extends Vue {
  @Ref() formInquery!: HTMLFormElement
  listDepartment: departmentModel[] = []
  listDepartmentSelected: departmentModel = new departmentModel()
  search = ''
  loading = false
  dialog = false
  title = ''
  validator = validator
  flag = ''
  headers = [
    {
      text: 'KODE RUANGAN',
      align: 'start',
      value: 'id',
      cellClass: 'text-uppercase' 
    },
    { 
      text: 'RUANGAN', 
      value: 'name',
      cellClass: 'text-uppercase' 
    },
    { 
      text: 'KODE EXTERNAL', 
      value: 'externalId',
      cellClass: 'text-uppercase' 
    },
    { 
      text: 'NAMA EXTERNAL', 
      value: 'displayName',
      cellClass: 'text-uppercase' 
    },
    { 
      text: 'KETERANGAN', 
      value: 'notes',
      cellClass: 'text-uppercase' 
    },
    { 
      text: 'ACTION', 
      value: 'action',
      cellClass: 'text-uppercase' 
    },
  ]

  loadData () {
    api.department.getAll().then((response) => {
      this.listDepartment = response.data.responseData
    }).catch((error) => {
      popUp.error(error)
    })
  }

  show (item: departmentModel) {
    this.title = 'Edit Department: ' + item.name
    this.listDepartmentSelected = item
    this.flag ='Edit'
    this.dialog = true
  }

  tambahData () {
    this.title = 'Tambah Data'
    this.flag = 'Create'
    this.listDepartmentSelected = new departmentModel()
    this.dialog = true
  }

  async simpan () {
    if (this.formInquery.validate()){
      if (this.flag === 'Edit') {
        await this.editDepartment(this.listDepartmentSelected)
      } else if (this.flag === 'Create') {
        await this.postDepartment(this.listDepartmentSelected)
      }
      this.loadData()
    }
  }

  hapus (item: departmentModel) {
    popUp.confirm('Apakah yakin anda yakin akan menghapus data?').then((confirm) => {
      if (confirm) {
        this.deleteDepartment(item.id)
      }
    })
  }

  async deleteDepartment(id: number) {
    try {
      popUp.showLoading()
      const response = await api.department.delete(id)
      this.dialog = false
      await popUp.successWithConfirmation(response.data.responseDesc)
      this.loadData()
    } catch (error) {
      popUp.error(error)
    } finally {
      popUp.dismisLoading()
    }
  }

  async postDepartment (item: departmentModel) {
    try {
      popUp.showLoading()
      const response = await api.department.create(item)
      this.dialog = false
      await popUp.successWithConfirmation(response.data.responseDesc)
    } catch (error) {
      popUp.error(error)
    } finally {
      popUp.dismisLoading()
    }
  }

  async editDepartment (item: departmentModel) {
    try {
      popUp.showLoading()
      const response = await api.department.update(item)
      this.dialog = false
      await popUp.successWithConfirmation(response.data.responseDesc)
    } catch (error) {
      popUp.error(error)
    } finally {
      popUp.dismisLoading()
    }
  }

  created () {
    this.loadData()
  }
}
