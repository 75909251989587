import settingModel from '@/models/settingModel'
import store from '@/store'
const defineDayName = [
  'Minggu','Senin','Selasa','Rabu','Kamis','Jumat','Sabtu'
]

const defineMonthName = [
  'Januari','Februari','Maret','April','Mei','Juni','Juli','Agustus','September','Oktober','Nopember','Desember'
]
 
function getSettingValueByName (name: string) {
  const settingData = store.getters['ApplicationModule/settingData'] || []
  const result = settingData.find((data: settingModel) => data.name === name)
  return result ? result.value : null
}


function checkMiliSecond(i: string) {
  if (Number(i) < 10) {i = '0' + i};
    return i;
}

function cacahStringToMp3 (val: string) {
  return new Promise<string[]>((resolve) => {
    const result: string[] = []
    let x = 0;
    for (let i of val) {
      if (isAlphaOrParen(i)) {
        const newFile = i.toLowerCase() + '.mp3'
        result.push(newFile)
       } else {
        const newval = val.substring(x, val.length)
        const toNumber = Number(newval)
        if ((toNumber <= 20) || (toNumber % 10 === 0)) {
          result.push(toNumber + '.mp3')
          break
        } else {
          if (toNumber < 100) {
            const newNumber = toNumber.toString()
            result.push(newNumber.substring(0,1) + '0.mp3')
            result.push(newNumber.substring(1,2) + '.mp3')
            break
          }
        }
       }
       x++
    }
    resolve(result)
  })
}

function isAlphaOrParen (str: string) {
  return /^[a-zA-Z()]+$/.test(str);
}

export default {
  checkTime (i: string) {
    if (Number(i) < 10) {i = '0' + i};
    return i;
  },
  liveTime () {
    let dayName = ''
    let longDate = ''
    let time = ''
    const today = new Date();
    dayName = defineDayName[today.getDay()]
    longDate = today.getDate() + ' ' + defineMonthName[today.getMonth()] + ' ' + today.getFullYear()
    let h = today.getHours();
    let m = today.getMinutes().toString();
    let s = today.getSeconds().toString();
    m = checkMiliSecond(m);
    s = checkMiliSecond(s);
    time =  h + ":" + m + ":" + s;
    return { time: time, longDate: longDate, dayName: dayName }
  },
  cacahStringToMp3,
  getSettingValueByName
}