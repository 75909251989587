
import Vue from 'vue'
import {Component} from 'vue-property-decorator'
import Swal from 'sweetalert2'
import store from '@/store'
import { Getter } from 'vuex-class'

@Component({
  components: {

  }
})

export default class Navbar extends Vue {
  @Getter('title', { namespace: 'topToolbar' }) title!: string
  dialog = false
  nav = [
    {
      icon: 'home',
      text: 'Home',
      title: 'Back to Home page',
      active: true
    },
    {
      icon: 'info',
      text: 'About',
      title: 'About this demo',
      active: false
    },
    {
      icon: 'assignment_turned_in',
      text: 'Todos',
      title: 'Some stuff that needs doing',
      active: false
    },
    {
      icon: 'email',
      text: 'Contact',
      title: 'Our Contact info',
      active: false
    }
  ]

  get userData() {
    return this.$store.state.user.user
  }

  gotoPortal(): void {
    if (this.userData) {
      this.$router.push({ path: '/dashboard' }).catch(() => {})
    } else {
      this.$router.push({ name: 'portal' })
    }
  }

  login () {
    this.$router.push({ name: 'login' })
  }

  logOut() {
    store.dispatch('logOut',localStorage.getItem('token')).then((response) => {
      this.showSuccessMessage(response.data.message)
      this.$router.push({ name: 'portal' })
    }).catch((error) => {
      this.showErrorMessage(error)
    })
  }

  async showSuccessMessage(message) {
    await Swal.fire({
      title: 'Success',
      text: message,
      icon: 'success',
      timer: 2000,
      showConfirmButton: false,
    });
  }

  async showErrorMessage(message) {
    await Swal.fire({
      title: 'Oops',
      text: message,
      icon: 'error',
      showConfirmButton: true,
    });
  }
}
