
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import TopToolbar from '@/components/layouts/TopToolbar.vue'
import MainFooter from '@/components/layouts/partials/MainFooter.vue'
import BaseNavbar from '@/components/layouts/partials/Navbar.vue'

@Component({
  components: {
    TopToolbar,
    MainFooter,
    BaseNavbar
  }
})
export default class MainLayout extends Vue {

}
