import counterModel from "@/models/admin/counterModel"
export default class departmentModel {
  id!: number
  name!: string
  notes!: string
  externalId!: string
  displayName!: string
  createdAt!: string
  updatedAt!: string
  queueLength!: number
  queueLetter!: string
  counters!: counterModel[]
}