import counterModel from './admin/counterModel'
export default class {
  channel!: string
  loket: counterModel = new counterModel
  department!: string
  timestamp: string = new Date().toString()
  data: CallData = new CallData()
}

class CallData {
  call!: string
}