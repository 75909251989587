import departmentModel from "@/models/admin/departmentModel"

export default class counterModel {
  id!: number
  name!: string
  departmentId!: number
  notes!: string
  displayName!: string
  externalId!: string
  createdAt!: string
  updatedAt!: string
  department!: departmentModel[]
}