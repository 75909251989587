
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Swal from 'sweetalert2'
import store from '@/store'
import { popUp } from '@/utils'

@Component
export default class TopToolbar extends Vue {
  @Getter('title', { namespace: 'topToolbar' }) title!: string
  @Getter('geUser', { namespace: 'AuthModuleHandler' }) user!: string
  @Getter('isAuth') isAuthenticated!: boolean
  @Getter('loginFormState', { namespace: 'ApplicationModule' }) loginFormState!: boolean

  @Watch('loginFormState')
  onChangeAuth () {
    const currentRoute = this.$route.name
    if (this.isAuthenticated === true && this.loginFormState === false && currentRoute !== 'portal') {
      window.location.reload()
    }
  }

  get userData() {
    return this.$store.state.user.user
  }

  gotoPortal(): void {
    this.$router.push({ name: 'portal' })
  }

  login () {
    store.dispatch('ApplicationModule/changeLoginFormState', true)
  }

  logOut() {
    popUp.confirm('Apakah anda yakin akan Logout ?').then((confirmed) => {
      if (confirmed) {
        store.dispatch('logOut',localStorage.getItem('token')).then((response) => {
          this.showSuccessMessage(response.data.message)
          const currentRouteRequiresAuth = this.$route.meta?.requiresAuth
          if (currentRouteRequiresAuth) {
            this.$router.push({ name: 'portal' })
          } else {
            window.location.reload()
          }
        }).catch((error) => {
          this.showErrorMessage(error)
        })
      }
    })
  }

  async showSuccessMessage(message) {
    await Swal.fire({
      title: 'Success',
      text: message,
      icon: 'success',
      timer: 2000,
      showConfirmButton: false,
    });
  }

  async showErrorMessage(message) {
    await Swal.fire({
      title: 'Oops',
      text: message,
      icon: 'error',
      showConfirmButton: true,
    });
  }
}
