

import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import store from '@/store'
import { Getter } from 'vuex-class'
import { general, popUp } from '@/utils'

@Component
export default class ShortCutMenu extends Vue {
  @Getter('isAuth') isAuthenticated!: boolean
  @Getter('loginFormState', { namespace: 'ApplicationModule' }) loginFormState!: boolean
  @Getter('getSettingDataState', { namespace: 'ApplicationModule' }) initialDataState!: boolean

  pathTemp = ''
  deptartmentIdPendaftaran = ''

  created () {
    this.onChangeInitialState()
  }

  @Watch('loginFormState')
  onChangeAuth () {
    if (this.isAuthenticated === true && this.loginFormState === false && this.pathTemp !== '') {
      window.location.assign(this.pathTemp)
    }
  }

  @Watch('initialDataState')
  async onChangeInitialState () {
    if (this.initialDataState === true) {
      try {
        await this.departmentPendaftaranIsSet()
      } catch (error) {
        popUp.error(error, 'Mengerti')
      }
    }
  }

  departmentPendaftaranIsSet () {
    return new Promise((resolve, reject) => {
      this.deptartmentIdPendaftaran = general.getSettingValueByName('DEPARTMENT-PENDAFTARAN')
      if (!this.deptartmentIdPendaftaran) {
        reject('Mapping department pendaftaran belum ditentukan, setup melalui menu admin.')
      } else {
        resolve(true)
      }
    })
  }

  openLoginForm () {
    store.dispatch('ApplicationModule/changeLoginFormState', true)
  }

  gotoGuardedPath (pathName: string) {
    this.pathTemp = pathName
    if (this.isAuthenticated) {
      this.$router.push(pathName)
    } else {
      this.openLoginForm()
    }
  }

  async gotoDisplayPath (pathName: string) {
    try {
      await this.departmentPendaftaranIsSet()
      this.$router.push(pathName)
    } catch (error) {
      popUp.error(error, 'Mengerti')
    }
  }
}
