export default class inqueryResponseModel {
  RuanganPerawatan!: string
  NoPendaftaran!: string
  NoIdentitas!: string
  NamaPasien!: string
  JK!: string
  Umur!: string
  JenisPasien!: string
  Kelas!: string
  TglMasuk!: string
  TglKeluar!: string
  StatusKeluar!: string
  KondisiPulang!: string
  KasusPenyakit!: string
  NoKamar!: string
  NoBed!: string
  Kecamatan!: string
  Alamat!: string
  DokterPemeriksa!: string
  UmurTahun!: string
  UmurBulan!: string
  UmurHari!: string
  KdKelas!: string
  KdJenisTarif!: string
  KdSubInstalasi!: string
  KdRuangan!: string
  IdDokter!: string
  Kelurahan!: string
  NamaPenjamin!: string
  NamaInstalasi!: string
  NoAntrian!: string
  KdInstalasi!: string
  KdKelompokPasien!: string
  NoPendaftaranRs!: string
  IdPenjamin!: string
  NoRM!: string
  NamaExternal!: string
  TglBatal!: string
  NoCM!: string
  TglLahir!: string
  Agama!: string
  Suku!: string
}