
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ShortCutMenu from '@/components/ShortCutMenu.vue'
@Component({
  components: {
    ShortCutMenu
  }
})
export default class Dashboard extends Vue {

}
