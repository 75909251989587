import Swal from 'sweetalert2'

const swalOptions = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674'
}

const info = function (message) {
  Swal.fire({
    icon: 'info',
    title: 'Informasi !',
    text: message,
    showCloseButton: true
  })
  Swal.update(swalOptions)
}

const confirm = function (message) {
  return new Promise((resolve) => {
    Swal.fire({
      icon: 'question',
      title: 'Konfirmasi',
      text: message,
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      resolve(result.value)
    })
    Swal.update(swalOptions)
  })
}

const showLoading = function (message = null) {
  Swal.fire({
    title: 'Mohon Tunggu !',
    html: message ?? 'Sedang memproses permintaan ....',
    allowOutsideClick: false,
    showConfirmButton: false,
    didOpen: () => {
      Swal.showLoading()
    }
  })
  Swal.update(swalOptions)
}

const dismisLoading = function () {
  Swal.close()
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const successToast = function (message) {
  Toast.fire({
    icon: 'success',
    title: message ?? 'Operating Completed'
  })
}

const success = function (message) {
  return new Promise((resolve) => {
    Swal.fire({
      icon: 'success',
      title: 'Sukses !',
      html: message ?? 'Operating Completed',
      showCloseButton: true,
      timer: 2000,
      showConfirmButton: false,
    }).then(() => {
      resolve(true)
    })
    Swal.update(swalOptions)
  })
}

const successWithConfirmation = function (message: string) {
  return new Promise((resolve) => {
    Swal.fire({
      icon: 'success',
      title: 'Sukses !',
      html: message ?? 'Operating Completed',
      showCloseButton: false
    }).then(() => {
      resolve(true)
    })
    Swal.update(swalOptions)
  })
}

const error = function (message, buttonText: string = 'Ok') {
  return new Promise((resolve) => {
    Swal.fire({
      icon: 'error',
      title: 'Kesalahan',
      text: message ?? 'Kesalahan',
      showCloseButton: false,
      confirmButtonText: buttonText
    }).then(() => {
      resolve(true)
    })
    Swal.update(swalOptions)
  })
}

export default {
  info,
  confirm,
  showLoading,
  dismisLoading,
  success,
  successToast,
  error,
  successWithConfirmation
}
