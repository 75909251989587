
import { Vue, Component, Ref } from 'vue-property-decorator'
import validator from '@/utils/validator'
import api from '@/api'
import store from '@/store'
import { popUp } from '@/utils'

@Component
export default class LoginForm extends Vue {
  @Ref() form!: HTMLFormElement

  showPassword = false
  validator = validator
  username = ''
  password = ''

  async login() {
    try {
      if (this.form.validate()) {
        popUp.showLoading()
        const credentials = {
          username: this.username,
          password: this.password,
        }
        const response = await api.auth.login(credentials)
        await store.dispatch('setToken', response.data.responseData.access_token)
        await store.dispatch('cekValidToken', response.data.responseData.access_token)
        await popUp.success('Login berhasil, mohon tunggu sedang mengalihkan anda ke halaman yang dituju.')
        this.close()
      }
    } catch (error) {
      popUp.error(error || 'An error occurred')
    }
  }

  close () {
    this.form.reset()
    store.dispatch('ApplicationModule/changeLoginFormState', false)
  }
}
