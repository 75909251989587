
import Vue from 'vue'
import { Component, Prop, Emit, Watch } from 'vue-property-decorator'
import listQueueLoketModel from '@/models/queues/listQueueLoketModel'

@Component
export default class CallsNavigation extends Vue {
  @Prop() queueList!: listQueueLoketModel[]
  @Prop() lastCall!: listQueueLoketModel[]
  selectedQueue: listQueueLoketModel = new listQueueLoketModel()
  tipe = ''

  nextCall () {
    const data = this.queueList
    const filteredArray = data.find(item => item.called === null)
    if (filteredArray) {
      this.selectedQueue = filteredArray
      this.tipe = 'newcall'
      this.values()
    }
  }

  reCall () {
    const data = this.lastCall[0]
    if (data) {
      this.selectedQueue = data
      this.tipe = 'recall'
      this.values()
    }
  }

  @Emit()
  values () {
    return {
      type: this.tipe,
      callData: this.selectedQueue,
    }
  }
}
