
import Vue from 'vue'
import { Component, Watch, Ref } from 'vue-property-decorator'

import { Getter } from 'vuex-class'
import store from '@/store'
import QueueHelper from '@/utils/queueHelper'
import api from '@/api'
import { popUp } from '@/utils'
import CallsNavigation from '@/components/calls/CallsNavigation.vue'

import callsDataModel from '@/models/calls/callsDataModel'
import validator from '@/utils/validator'
import requestPrintModel from '@/models/requestPrintModel'
import inqueryResponseModel from '@/models/inqueryResponseModel'
import listQueueLoketModel from '@/models/queues/listQueueLoketModel'
import CallMessageWsModel  from '@/models/CallMessageWsModel'
import createQueuePolyRequest from '@/models/queues/createQueuePolyRequest'
import UserModel from '@/models/auth/userModel'
import { general } from '@/utils'

@Component({
  components: {
    CallsNavigation
  }
})

export default class AntrianLoket extends Vue {
  @Getter('isConnected', { namespace: 'WsMessageHandler' }) wsConnectionState!: boolean
  @Getter('message', { namespace: 'WsMessageHandler' }) wsMessage!: string
  @Getter('user') authAuthDetail!: UserModel
  @Getter('getSettingDataState', { namespace: 'ApplicationModule' }) initialDataState!: boolean

  @Ref() formInquery!: HTMLFormElement
  currentCallData: CallMessageWsModel = new CallMessageWsModel()
  subscribedChannel = ['newQueue','newcall','recall']
  queue: QueueHelper = new QueueHelper()
  listAntrian: listQueueLoketModel[] = []
  search=''
  loading = false
  showInquery = false
  validator = validator
  noCM = ''
  resultInquery: inqueryResponseModel[] = []
  showData = false
  callsData: callsDataModel = new callsDataModel()
  currentDepartmentId = ''
  lastCall: listQueueLoketModel[] = []
  indexRegistrationDataSelected = null
  registrationDataSelected: inqueryResponseModel = new inqueryResponseModel()
  queueSelected: listQueueLoketModel = new listQueueLoketModel()

  @Watch('indexRegistrationDataSelected')
  onChangeSelectedData () {
    if (this.indexRegistrationDataSelected || this.indexRegistrationDataSelected === 0) {
      this.registrationDataSelected = this.resultInquery[this.indexRegistrationDataSelected]
    } else {
      this.registrationDataSelected = new inqueryResponseModel()
    }
  }

  @Watch('wsMessage', { deep: true })
  onChangeMessage (newVal: string) {
    const jsonData = JSON.parse(newVal)
    const newChannel = jsonData.channel || ''
    if (newChannel && this.subscribedChannel.find(el => el === newChannel)) {
      this.listQueue()
      this.getLastCall()
    }
  }

  @Watch('initialDataState')
  async onChangeInitialState () {
    if (this.initialDataState === true) {
      const deptartmentId = general.getSettingValueByName('DEPARTMENT-PENDAFTARAN')
      if (deptartmentId) {
        this.currentDepartmentId = deptartmentId
        if (this.isUserHaveCounter()) {
          this.listQueue()
          this.getLastCall()
        } else {
          await popUp.error('Profile anda belum lengkap, hubungi admin untuk update profile.', 'Mengerti')
          this.$router.back()
        }
      } else {
        await popUp.error('Setup aplikasi belum lengkap, hubungi admin untuk melanjutkan.', 'Mengerti')
        this.$router.back()
      }
    }
  }

  dialog = false
  dialogDelete = false
  headers = [
    {
      text: 'NO',
      align: 'start',
      sortable: false,
      value: 'number',
      cellClass: 'text-uppercase'
    },
    { 
      text: 'ANTRIAN', 
      value: 'letter',
      cellClass: 'text-uppercase' 
    },
    { 
      text: 'TUJUAN',
      align: 'start', 
      value: 'tujuan',
      sortable: false,
    },
    { 
      text: 'LOKET', 
      value: 'counter.name',
      cellClass: 'text-uppercase' 
    },
    { 
      text: 'ACTION', 
      value: 'actions', 
      sortable: false,
      cellClass: 'text-uppercase' 
     },
  ]


  created () {
    this.onChangeInitialState()
  }

  get userData() {
    return this.$store.state.user.user
  }

  async listQueue () {
    try {
      this.loading = true
      const response = await api.queue.getListQueue(Number(this.currentDepartmentId),this.toDay().toString(),'pendaftaran')
      const tempResult = response.data.responseData || []
      tempResult.map((data, index) => {
        return data.number = index + 1
      })
      this.listAntrian = tempResult
    } catch (error) {
      popUp.error(error)
    } finally {
      this.loading = false
    }
  }

  async call (item: listQueueLoketModel, callType: string) {
    try {
      popUp.showLoading()
      const formData: callsDataModel = new callsDataModel()
      formData.queueId = item.id
      formData.counterId = this.userData.counterId
      formData.callsType = callType
      await this.postCall(formData)
      const callData: CallMessageWsModel = new CallMessageWsModel()
      callData.channel = callType
      callData.loket = this.userData.counter
      callData.data.call = item.letter
      this.sendCall(callData)
      popUp.dismisLoading()
      popUp.successToast('Nomor Antrian : '+ item.letter +' berhasil di panggil')
    } catch (error) {
      popUp.error(error)
    }
  }

  async endCall (item: listQueueLoketModel, callType: string) {
    return new Promise((resolve, reject) => {
      this.callsData = new callsDataModel()
      const formData: callsDataModel = new callsDataModel()
      formData.queueId = item.id
      formData.counterId = this.userData.counterId
      formData.callsType = callType
      this.postCall(formData).then(() => {
        resolve(true)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  postCall (item: callsDataModel) {
    return new Promise ((resolve, reject) => {
      api.calls.postCalls(item).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  sendCall (data: CallMessageWsModel) {
    store.dispatch('WsMessageHandler/sendCallMessage', JSON.stringify(data))
  }

  @Watch('showInquery')
  resetData () {
    if (!this.showInquery) {
      this.formInquery.reset()
      this.showData = false
      this.resultInquery = []
      this.indexRegistrationDataSelected = null
    }
  }

  async inquery() {
    if (this.formInquery.validate()) {
      this.showData = false
      popUp.showLoading()
      try {
        const date = this.toDay().toString()
        await this.postInquery(this.noCM, date)
        popUp.dismisLoading()
      } catch (error) {
        popUp.error(error || 'An error occurred')
      }
    }
  }

  toDay () {
    let result = ''
    const today = new Date(new Date().toLocaleString('en-US'))

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0') // Month is 0-based, so add 1 and pad with '0' if needed.
    const day = String(today.getDate()).padStart(2, '0')

    const formattedDate = `${year}-${month}-${day}`
    result = formattedDate
    return result
  }

  printQueuePoli (formData: requestPrintModel) {
    return new Promise ((resolve, reject) => {
      api.printServer.sendPrintQueuePoly(formData).then(({ data }) => {
        resolve(true)
      }).catch(() => {
        reject('Gagal saat cetak nomor antrian poli, periksa sambungan printer.')
      })
    })
  }

  async cetakAntrianPoli() {
    try {
      popUp.showLoading()
      await this.createQueuePoli(this.registrationDataSelected)
      await this.endCall(this.queueSelected,'end')
      await this.listQueue()
      await this.getLastCall()
      this.showInquery = false
      const formRequest: requestPrintModel = new requestPrintModel()
      formRequest.dokterPemeriksa = this.registrationDataSelected.DokterPemeriksa
      formRequest.noAntrian = this.registrationDataSelected.NoAntrian
      formRequest.ruanganPerawatan = this.registrationDataSelected.RuanganPerawatan
      formRequest.printerName = 'Receipt'
      formRequest.noPendaftaran = this.registrationDataSelected.NoPendaftaran
      formRequest.namaPasien = this.registrationDataSelected.NamaPasien
      formRequest.tglLahir= this.registrationDataSelected.TglLahir
      formRequest.jenisKelamin = this.registrationDataSelected.JK
      formRequest.ruang = this.registrationDataSelected.RuanganPerawatan
      formRequest.dokter = this.registrationDataSelected.DokterPemeriksa
      formRequest.alamat = this.registrationDataSelected.Alamat
      await this.printQueuePoli(formRequest)
      popUp.successToast('Nomor antrian poli berhasil di cetak.')
    } catch (error) {
      popUp.error(error)
    }
  }
  
  createQueuePoli(item: inqueryResponseModel) {
    const formData: createQueuePolyRequest = new createQueuePolyRequest()
    formData.date = item.TglMasuk
    formData.noPendaftaran = item.NoPendaftaran
    formData.noIdentitas = item.NoIdentitas
    formData.namaPasien = item.NamaPasien
    formData.jenisKelamin = item.JK
    formData.umur = item.Umur
    formData.tglMasuk = item.TglMasuk
    formData.kecamatan = item.Kecamatan
    formData.alamat = item.Alamat
    formData.kodeKelas = item.KdKelas
    formData.kodeJenisTarif= item.KdJenisTarif
    formData.kdRuangan = item.KdRuangan
    formData.namaRuangan = item.RuanganPerawatan
    formData.idDokter = item.IdDokter
    formData.namaDokter = item.DokterPemeriksa
    formData.kelurahan = item.Kelurahan
    formData.noAntrian = item.NoAntrian
    formData.kdInstalasi = item.KdInstalasi
    formData.kdKelompokPasien = item.KdKelompokPasien
    formData.idPenjamin = item.IdPenjamin
    formData.noRm = item.NoCM
    formData.noCm = item.NoCM
    formData.tglLahir = item.TglLahir
    formData.parentQueueId = this.queueSelected.id.toString()

    return new Promise ((resolve, reject) => {
      api.queue.createPolyclinicQueue(formData).then(({ data }) => {
        resolve(data.responseData)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  postInquery(noCM: string, date: string) {
    return new Promise <inqueryResponseModel> ((resolve, reject) => {
      api.simrs.inqueryPendaftaran(noCM ,date).then(({ data }) => {
        this.resultInquery = data.data
        this.showData = true
        resolve(data.responseData)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  isUserHaveCounter () {
    return this.authAuthDetail.counterId !== undefined && this.authAuthDetail.counterId !== null
  }

  getLastCall () {
    return new Promise((resolve, reject) => {
      api.calls.lastCallsWithCounter(this.toDay().toString(), Number(this.currentDepartmentId), Number(this.userData.counterId), 2).then((result) => {
        this.lastCall = result.data.responseData || []
        resolve(result.data.responseData)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  showInqueryAction (data: listQueueLoketModel) {
    this.queueSelected = Object.assign({}, data)
    this.showInquery = true
  }

  callData (data: {type: string, callData: listQueueLoketModel}) {
    this.call(data.callData, data.type)
  }
}
